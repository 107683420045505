import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import mixins from './mixin/mixins'
import { createI18n } from 'vue-i18n'
import messages from './locales/i18n'
import VueDatePicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'
import PrimeVue from 'primevue/config'
import VueSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

const i18n = createI18n({
  // something vue-i18n options
  locale: 'ko',
  fallbackLocale: 'en',
  messages
})

createApp(App)
  .use(router)
  .use(PrimeVue, {
    locale: {
      startsWith: '으로 시작하는',
      contains: '포함하는',
      notContains: '포함하지 않는',
      endsWith: '으로 끝나는',
      equals: '같은',
      notEquals: '같지 않는',
      noFilter: '필터하지 않은',
      filter: '필터',
      lt: '보다 작은',
      lte: '보다 같거나 작은',
      gt: '보다 큰',
      gte: '보다 같거나 큰',
      dateIs: '날짜가 같은',
      dateIsNot: '날짜가 같지 않은',
      dateBefore: '날짜 전',
      dateAfter: '날짜 후',
      custom: '커스텀',
      clear: '초기화',
      apply: '적용',
      matchAll: '모두 맞는',
      matchAny: '하나라도 맞는',
      addRule: '규칙 추가',
      removeRule: '규칙 제거',
      accept: '예',
      reject: '아니오',
      choose: '선택',
      upload: '업로드',
      cancel: '취소',
      completed: '완료',
      pending: '대기',
      dayNames: [
        '일요일',
        '월요일',
        '화요일',
        '수요일',
        '목요일',
        '금요일',
        '토요일'
      ],
      dayNamesShort: ['일', '월', '화', '수', '목', '금', '토'],
      dayNamesMin: ['일', '월', '화', '수', '목', '금', '토'],
      monthNames: [
        '1월',
        '2월',
        '3월',
        '4월',
        '5월',
        '6월',
        '7월',
        '8월',
        '9월',
        '10월',
        '11월',
        '12월'
      ],
      monthNamesShort: [
        '1월',
        '2월',
        '3월',
        '4월',
        '5월',
        '6월',
        '7월',
        '8월',
        '9월',
        '10월',
        '11월',
        '12월'
      ],
      chooseYear: '년을 선택하세요',
      chooseMonth: '월을 선택하세요',
      chooseDate: '일을 선택하세요',
      prevDecade: '10년 전',
      nextDecade: '10년 후',
      prevYear: '작년',
      nextYear: '내년',
      prevMonth: '전달',
      nextMonth: '다음달',
      prevHour: '이전 시간',
      nextHour: '다음 시간',
      prevMinute: '이전 분',
      nextMinute: '다음 분',
      prevSecond: '이전 초',
      nextSecond: '다음 초',
      am: 'AM',
      pm: 'PM',
      today: '오늘',
      weekHeader: 'Wk',
      firstDayOfWeek: 0,
      showMonthAfterYear: true,
      dateFormat: 'yy-mm-dd',
      weak: '약함',
      medium: '보통',
      strong: '강함',
      passwordPrompt: '패스워드를 입력하세요',
      emptyFilterMessage: '결과가 없습니다.',
      searchMessage: '{0} 개 사용 가능',
      selectionMessage: '{0} 개 선택',
      emptySelectionMessage: '선택되지 않았습니다',
      emptySearchMessage: '결과가 없습니다',
      emptyMessage: '가능한 선택이 없습니다.',
      aria: {
        trueLabel: '참',
        falseLabel: '거짓',
        nullLabel: '선택되지 않음',
        star: '별 1개',
        stars: '별 {star}개',
        selectAll: '모두 선택',
        unselectAll: '모두 선택되지 않음',
        close: '닫기',
        previous: '이전',
        next: '다음',
        navigation: '네비게이션',
        scrollTop: '스크롤 위로',
        moveTop: '맨위로 이동',
        moveUp: '위로 이동',
        moveDown: '아래로 이동',
        moveBottom: '맨아래로 이동',
        moveToTarget: '타겟으로 이동',
        moveToSource: '소스로 이동',
        moveAllToTarget: '타겟으로 모두 이동',
        moveAllToSource: '소스로 모두 이동',
        pageLabel: '페이지 {page}',
        firstPageLabel: '첫 페이지',
        lastPageLabel: '마지막 페이지',
        nextPageLabel: '다음 페이지',
        previousPageLabel: '이전 페이지',
        rowsPerPageLabel: '페이지별 행 수',
        jumpToPageDropdownLabel: '페이지 드롭다운라벨로 점프',
        jumpToPageInputLabel: '페이지 입력라벨로 점프',
        selectRow: '행 선택됨',
        unselectRow: '행 선택되지 않음',
        expandRow: '행 확장됨',
        collapseRow: '행 접힘',
        showFilterMenu: '필터 메뉴 보이기',
        hideFilterMenu: '필터 메뉴 숨기기',
        filterOperator: '필터 Operator',
        filterConstraint: '필터 Constraint',
        editRow: '행 편집',
        saveEdit: '편집 저장',
        cancelEdit: '편집 취소',
        listView: '리스트 보기',
        gridView: '그리드 보기',
        slide: '슬라이드',
        slideNumber: '{slideNumber}',
        zoomImage: '확대/축소 이미지',
        zoomIn: '확대',
        zoomOut: '축소',
        rotateRight: '오른쪽으로 회전',
        rotateLeft: '왼쪽으로 회전'
      }
    }
  })
  .mixin(mixins)
  .use(store)
  .use(i18n)
  .component('VueDatePicker', VueDatePicker)
  .component('VueSelect', VueSelect)
  .directive('focus', {
    // focus 전역함수 => v-focus로 사용 가능
    mounted(el) {
      el.focus()
    }
  })
  .mount('#app')
