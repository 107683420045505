<template>
  <div class="modal fade" id="myPage" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="staticBackdropLabel">{{ $t('myPage.modify') }}</h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" ref="myButton"></button>
        </div>
        <div class="modal-body">
          <form id="detailForm">
            <table class="table_view table--block">
              <tbody>
              <tr>
                <!-- 아이디 -->
                <th><strong class="orange_font">*</strong> {{ $t('myPage.id') }}</th>
                <td data-label="아이디">
                  <input type="text" class="wi100" readonly v-model="form.loginId"/>
                </td>
                <!-- 이메일 -->
                <th>{{ $t('myPage.email') }}</th>
                <td data-label="이메일">
                  <input type="text" class="wi100" v-model="form.email"/>
                </td>
              </tr>
              <tr>
                <!-- 성명 -->
                <th><strong class="orange_font">*</strong> {{ $t('myPage.name') }}</th>
                <td data-label="성명">
                  <input type="text" class="wi100" v-model="form.name"/>
                </td>
                <!-- 그리드 Row 갯수 -->
                <th><small style="color: red;font-weight: bold;">&#42;&nbsp;</small>{{ $t('register.gridRow') }}<br>{{ $t('register.count') }}</th>
                <td>
                  <div class="form-inline input-group-sm">
                    <select v-model="form.gridRowCount" class="form-control" style="height: 33px;">
                      <option v-for="gridRow in gridRowList" :value="gridRow.ref1" :key="gridRow.ref1">
                        {{ gridRow.ref1 }}
                      </option>
                    </select>
                  </div>
                </td>
              </tr>
              <tr>
                <!-- 전화번호1 -->
                <th><strong class="orange_font">*</strong> {{ $t('myPage.tel1') }}</th>
                <td data-label="전화번호1">
                  <input type="text" class="wi100" v-model="form.phoneNumber1" @keyup="phoneFormatter(this.form.phoneNumber1, 'phoneNumber1')"/>
                </td>
                <!-- 전화번호2 -->
                <th>{{ $t('myPage.tel2') }}</th>
                <td data-label="전화번호2">
                  <input type="text" class="wi100" v-model="form.phoneNumber2" @keyup="phoneFormatter(this.form.phoneNumber2, 'phoneNumber2')"/>
                </td>
              </tr>
              <tr>
                <!-- 언어 -->
                <th><strong class="orange_font">*</strong> {{ $t('myPage.language') }}</th>
                <td colspan="1">
                  <select v-model="form.language" class="wi100">
                    <option value="ko">한국어</option>
                    <option value="en">English</option>
                    <option value="jp">にほん</option>
                  </select>
                </td>
              </tr>
              <tr>
                <!-- 현재 비밀번호 -->
                <th>{{ $t('myPage.nowPassword') }}</th>
                <td>
                  <input type="password" class="wi100" v-model="nowPassword" autocomplete="new-password"/>
                </td>
              </tr>
              <tr>
                <!-- 비밀번호 변경 -->
                <th>{{ $t('myPage.passwordModify') }}</th>
                <td data-label="비밀번호 변경">
                  <input type="password" class="wi100" v-model="form.password" autocomplete="new-password"/>
                </td>
                <!-- 비밀번호 확인 -->
                <th style="border-top: 1px solid #dcdcdc;">{{ $t('myPage.password') }}<br> {{ $t('myPage.modifyConfirm') }}</th>
                <td style="border-top: 1px solid #dcdcdc;">
                  <input type="password" class="wi100" v-model="form.password2" autocomplete="new-password"/>
                </td>
              </tr>
              </tbody>
            </table>
          </form>
          <!-- *표시가 있는 것은 필수 값 -->
          <p class="text-left">{{ $t('textMessage.starRequired') }}</p>
          <!-- ※ 비밀번호 변경 시 현재 비밀번호 필수 입력  -->
          <p class="text-left mt5">{{ $t('textMessage.passwordRequired') }}</p>
          <!-- ※ 현재 비밀번호 입력 시 비밀번호 변경 및 비밀번호 변경 확인 항목은 필수 값입니다.  -->
          <p class="text-left mt5">{{ $t('textMessage.passwordModifiedRequired') }}</p>
          <!-- ※ 비밀번호 변경과 비밀번호 변경 확인은 일치 해야 합니다.  -->
          <p class="text-left mt5">{{ $t('textMessage.passwordSame') }}</p>
        </div>
        <div class="modal-footer">
          <button @click="register" type="button" class="btn btn-primary">{{ $t('button.save') }}</button>
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">{{ $t('button.close') }}</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import store from '@/store'
import axios from 'axios'
import { mapGetters, mapActions } from 'vuex'

export default {
  computed: {
    ...mapGetters(['serviceType']),
    serviceTypeMapped() {
      const serviceType = this.serviceType
      // serviceType 값에 따라 대체할 값을 반환합니다.
      return serviceType === 'C' ? 'charger' : (serviceType === 'H' ? 'appliance' : serviceType)
    }
  },
  data() {
    return {
      form: {
        loginId: '',
        name: '',
        email: '',
        teamId: '',
        phoneNumber1: '',
        phoneNumber2: '',
        password: '',
        password2: '',
        passwordChk: '',
        language: 'ko',
        gridRowCount: 10
      },
      nowPassword: '',
      gridRowList: []
    }
  },
  mounted() {
    const authToken = sessionStorage.getItem('authToken')

    if (authToken !== null) {
      this.form.loginId = store.state.user.id.loginId
      this.setData()
    }
  },
  methods: {
    async register() {
      if (this.form.name === '') { alert(this.$t('message.nameRequired')); return false }
      if (this.form.gridRowCount === '') { alert(this.$t('message.gridRowCountRequired')); return false }
      if (this.form.phoneNumber1 === '') { alert(this.$t('message.phoneNumber1Required')); return false }
      if (this.form.phoneNumber1.length > 15) { alert(this.$t('message.phoneNumber1Required')); return false }
      if (this.form.phoneNumber2.length > 15) { alert(this.$t('message.phoneNumber1Required')); return false }
      if (this.form.language === '') { alert(this.$t('message.languageRequired')); return false }
      if (this.nowPassword !== '') {
        if (this.form.password === '' || this.form.password === undefined) { alert(this.$t('message.passwordRequired')); return false }
        if (this.form.password2 === '' || this.form.password2 === undefined) { alert(this.$t('message.password2Required')); return false }
        if (this.form.password !== this.form.password2) {
          alert(this.$t('message.samePasswordRequired')); return false
        }
      }

      if (confirm(this.$t('message.saved'))) {
        this.form.phoneNumber1 = this.$phoneFormatterHyphen(this.form.phoneNumber1)
        this.form.phoneNumber2 = this.$phoneFormatterHyphen(this.form.phoneNumber2)
        this.form.passwordChk = this.form.password2

        let result = ''
        result = await this.$api(`/api/${this.serviceTypeMapped}/my-info/${this.form.loginId}`, 'patch', this.form)
        if (result.status === 200) {
          await this.reToken()
          alert(this.$t('message.saved200'))
          window.location.reload()
        } else if (result.status === 400) {
          alert(this.$t(`errorMessage.${result.data.code}`))
          return false
        } else if (result.status === 500) {
          alert(this.$t('message.saved400'))
          return false
        }
      }
    },
    async setData() {
      let gridList
      await this.$getApi(`/api/${this.serviceTypeMapped}/my-info/${this.form.loginId}`)
        .then(response => {
          this.form = response.data
          this.form.phoneNumber1 = this.$phoneFormatter(response.data.phoneNumber1)
          this.form.phoneNumber2 = this.$phoneFormatter(response.data.phoneNumber2)
          this.setAdminTeamType(response.data.adminTeamType)
        })
        .catch(error => { console.log(error) })

      await this.$getApi(`/api/${this.serviceTypeMapped}/classification/code-sub?codeMain=ko-gridRow`)
        .then(response => {
          // grid 숫자 낮은 순으로  재 정렬
          gridList = response.data
          gridList.sort((a, b) => parseInt(a.ref1) - parseInt(b.ref1))

          this.gridRowList = gridList
        })
        .catch(error => { console.log(error) })
    },
    async reToken() {
      const headers = {
        Authorization: store.state.user.id.authToken
      }
      await axios({
        url: `/api/${this.serviceTypeMapped}/auth/token/refresh`,
        method: 'post',
        headers: headers
      })
        .then((res) => {
          const authToken = res.data
          this.$store.commit('user', { loginId: this.form.loginId, authToken })
          sessionStorage.setItem('authToken', authToken)
        })
        .catch(error => { console.log(error) })
    },
    phoneFormatter(value, name) {
      const formattedNum = this.$phoneFormatter(value)
      if (name === 'phoneNumber1') {
        this.form.phoneNumber1 = formattedNum
      } else if (name === 'phoneNumber2') {
        this.form.phoneNumber2 = formattedNum
      }
    },
    ...mapActions(['setAdminTeamType'])
  }
}
</script>
