<template>
  <div id="container">
    <article class="titleWrap" id="contentTopWrap">
      <div id="contentsGroup">
        <h2>{{ $t('menu.dashboard') }}</h2>
      </div>
    </article>
    <div class="dashboard_flex">
      <div class="dahsboard_flex_col dahsboard_flex_col_4">
        <div class="title_btn clearfix">
          <!-- 오늘의 고장현황 -->
          <h3 class="title fl_l">{{ $t('dashboard.todayBrokenStatus') }} </h3>
        </div>
        <div class="dashboard_inner">
          <div class="flex_box" v-for="brokenStatus in brokenStatusList" :key="brokenStatus.chargeSpeedCode">
            <!-- 급속 -->
            <p class="box_tit">{{ brokenStatus.chargeSpeedName }}</p>
            <div class="flex_box_num"><strong>{{ brokenStatus.count }}</strong></div>
          </div>
        </div>
      </div>
      <div class="dahsboard_flex_col dahsboard_flex_col_6">
        <div class="title_btn clearfix">
          <!-- 수리현황 -->
          <h3 class="title fl_l">{{ $t('dashboard.repairStatus') }} </h3>
          <div class="btn_wrap fl_r">
            <button @click="changeRepairStatus('TODAY')" class="point_btn">{{ $t('button.today') }}</button>
            <button @click="changeRepairStatus('WEEK')" class="point_btn">{{ $t('button.lastWeek') }}</button>
            <button @click="changeRepairStatus('MONTH')" class="point_btn">{{ $t('button.lastMonth') }}</button>
            <button @click="changeRepairStatus('YEAR')" class="point_btn">{{ $t('button.lastYear') }}</button>
          </div>
        </div>
        <div class="dashboard_inner">
          <div class="flex_box">
            <!-- 총계 -->
            <p class="box_tit">{{ $t('dashboard.summation') }}</p>
            <div class="flex_box_num"><strong>{{ repairStatus.summation }}</strong></div>
          </div>
          <div class="flex_box">
            <!-- 접수 -->
            <p class="box_tit">{{ $t('dashboard.request') }}</p>
            <div class="flex_box_num"><strong>{{ repairStatus.request }}</strong></div>
          </div>
          <div class="flex_box">
            <!-- 미처리 -->
            <p class="box_tit">{{ $t('dashboard.unprocessed') }}</p>
            <div class="flex_box_num"><strong>{{ repairStatus.unprocessed }}</strong></div>
          </div>
          <div class="flex_box">
            <!-- 완료 -->
            <p class="box_tit">{{ $t('dashboard.complete') }}</p>
            <div class="flex_box_num"><strong>{{ repairStatus.complete }}</strong></div>
          </div>
        </div>
      </div>
      <div class="dahsboard_flex_col dahsboard_flex_col_10">
        <div class="title_btn clearfix" style="margin-bottom: 10px;">
          <!-- 지역별 접수 현황 -->
          <h3 class="title fl_l">{{ $t('dashboard.areaRequestStatus') }}</h3>
          <div class="btn_wrap fl_r">
            <button @click="changeAreaRequestStatus('TODAY')" class="point_btn">{{ $t('button.today') }}</button>
            <button @click="changeAreaRequestStatus('WEEK')" class="point_btn">{{ $t('button.lastWeek') }}</button>
            <button @click="changeAreaRequestStatus('MONTH')" class="point_btn">{{ $t('button.lastMonth') }}</button>
            <button @click="changeAreaRequestStatus('YEAR')" class="point_btn">{{ $t('button.lastYear') }}</button>
          </div>
        </div>
        <div class="dashboard_inner">
          <div class="flex_box">
            <!-- 총계 -->
            <p class="box_tit">{{ $t('dashboard.summation') }}</p>
            <div class="flex_box_num"><strong class="non">{{ areaRequestStatus.summation }}</strong></div>
          </div>
          <div class="flex_box">
            <!-- 서울 -->
            <p class="box_tit">{{ $t('dashboard.seoul') }}</p>
            <div class="flex_box_num"><strong class="non">{{ areaRequestStatus.seoul }}</strong></div>
          </div>
          <div class="flex_box">
            <!-- 부산 -->
            <p class="box_tit">{{ $t('dashboard.busan') }}</p>
            <div class="flex_box_num"><strong class="non">{{ areaRequestStatus.busan }}</strong></div>
          </div>
          <div class="flex_box">
            <!-- 대구 -->
            <p class="box_tit">{{ $t('dashboard.daegu') }}</p>
            <div class="flex_box_num"><strong class="non">{{ areaRequestStatus.daegu }}</strong></div>
          </div>
          <div class="flex_box">
            <!-- 인천 -->
            <p class="box_tit">{{ $t('dashboard.incheon') }}</p>
            <div class="flex_box_num"><strong class="non">{{ areaRequestStatus.incheon }}</strong></div>
          </div>
          <div class="flex_box">
            <!-- 광주 -->
            <p class="box_tit">{{ $t('dashboard.gwangju') }}</p>
            <div class="flex_box_num"><strong class="non">{{ areaRequestStatus.gwangju }}</strong></div>
          </div>
          <div class="flex_box">
            <!-- 대전 -->
            <p class="box_tit">{{ $t('dashboard.daejeon') }}</p>
            <div class="flex_box_num"><strong class="non">{{ areaRequestStatus.daejeon }}</strong></div>
          </div>
          <div class="flex_box">
            <!-- 울산 -->
            <p class="box_tit">{{ $t('dashboard.ulsan') }}</p>
            <div class="flex_box_num"><strong class="non">{{ areaRequestStatus.ulsan }}</strong></div>
          </div>
          <div class="flex_box">
            <!-- 세종 -->
            <p class="box_tit">{{ $t('dashboard.sejong') }}</p>
            <div class="flex_box_num"><strong class="non">{{ areaRequestStatus.sejong }}</strong></div>
          </div>
          <div class="flex_box">
            <!-- 경기 -->
            <p class="box_tit">{{ $t('dashboard.gyeonggi') }}</p>
            <div class="flex_box_num"><strong class="non">{{ areaRequestStatus.gyeonggi }}</strong></div>
          </div>
          <div class="flex_box">
            <!-- 강원 -->
            <p class="box_tit">{{ $t('dashboard.gangwon') }}</p>
            <div class="flex_box_num"><strong class="non">{{ areaRequestStatus.gangwon }}</strong></div>
          </div>
          <div class="flex_box">
            <!-- 충청북도 -->
            <p class="box_tit">{{ $t('dashboard.northChungcheong') }}</p>
            <div class="flex_box_num"><strong class="non">{{ areaRequestStatus.northChungcheong }}</strong></div>
          </div>
          <div class="flex_box">
            <!-- 충청남도 -->
            <p class="box_tit">{{ $t('dashboard.southChungcheong') }}</p>
            <div class="flex_box_num"><strong class="non">{{ areaRequestStatus.southChungcheong }}</strong></div>
          </div>
          <div class="flex_box big">
            <!-- 전북특별자치도 -->
            <p class="box_tit">{{ $t('dashboard.northJeolla') }}</p>
            <div class="flex_box_num"><strong class="non">{{ areaRequestStatus.northJeolla }}</strong></div>
          </div>
          <div class="flex_box">
            <!-- 전라남도 -->
            <p class="box_tit">{{ $t('dashboard.southJeolla') }}</p>
            <div class="flex_box_num"><strong class="non">{{ areaRequestStatus.southJeolla }}</strong></div>
          </div>
          <div class="flex_box">
            <!-- 경상북도 -->
            <p class="box_tit">{{ $t('dashboard.northGyeongsang') }}</p>
            <div class="flex_box_num"><strong class="non">{{ areaRequestStatus.northGyeongsang }}</strong></div>
          </div>
          <div class="flex_box">
            <!-- 경상남도 -->
            <p class="box_tit">{{ $t('dashboard.southGyeongsang') }}</p>
            <div class="flex_box_num"><strong class="non">{{ areaRequestStatus.southGyeongsang }}</strong></div>
          </div>
          <div class="flex_box">
            <!-- 제주 -->
            <p class="box_tit">{{ $t('dashboard.jeju') }}</p>
            <div class="flex_box_num"><strong class="non">{{ areaRequestStatus.jeju }}</strong></div>
          </div>
        </div>
      </div>
      <div class="dahsboard_flex_col dahsboard_flex_col_10">
        <div class="title_btn clearfix">
          <!-- 오늘의 충전기 가동 현황 -->
          <h3 class="title fl_l">{{ $t('dashboard.todayChargerStatus') }}</h3>
        </div>
        <div class=" scroll">
          <table class="tableA ">
            <thead>
              <tr>
                <!-- 구분 -->
                <th>{{ $t('dashboard.gubun') }}</th>
                <td v-for="item in chargerStatusList" :key="item">{{ item.name }}</td>
              </tr>
            </thead>
            <tbody>
            <tr>
              <!-- 정상 충전기 수 -->
              <th>{{ $t('dashboard.operating') }} <br> {{ $t('dashboard.chargerCount') }}</th>
              <td v-for="item in chargerStatusList" :key="item">{{ item.normalCount }}</td>
            </tr>
            <tr>
              <!-- 고장접수 충전기 수 -->
              <th>{{ $t('dashboard.repairRequest') }} <br> {{ $t('dashboard.chargerCount') }}</th>
              <td v-for="item in chargerStatusList" :key="item">{{ item.brokenCount }}</td>
            </tr>
            <tr>
              <!-- 전체 충전기 수 -->
              <th>{{ $t('dashboard.all') }} <br> {{ $t('dashboard.chargerCount') }}</th>
              <td v-for="item in chargerStatusList" :key="item">{{ item.totalCount }}</td>
            </tr>
            <tr>
              <!-- 가동율(%) -->
              <th>{{ $t('dashboard.operatingRate') }}</th>
              <td v-for="item in chargerStatusList" :key="item">{{ item.percent }} %</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  data() {
    return {
      brokenStatusList: [{
        chargeSpeedCode: '',
        chargeSpeedName: '',
        count: 0
      }],
      repairStatus: {
        summation: '0',
        request: '0',
        unprocessed: '0',
        complete: '0',
        assignment: '0',
        delay: '0',
        repair: '0',
        revisit: '0',
        visit: '0'
      },
      areaRequestStatus: {
        busan: '0',
        daegu: '0',
        daejeon: '0',
        gangwon: '0',
        gwangju: '0',
        gyeonggi: '0',
        incheon: '0',
        jeju: '0',
        northChungcheong: '0',
        northGyeongsang: '0',
        northJeolla: '0',
        sejong: '0',
        seoul: '0',
        southChungcheong: '0',
        southGyeongsang: '0',
        southJeolla: '0',
        ulsan: '0',
        summation: '0'
      },
      chargerStatusList: [{
        name: '',
        count: 0,
        percent: 0
      }],
      language: 'ko',
      day: 1,
      time: 1
    }
  },
  mounted() {
    this.setData()

    this.language = this.$tokenDecode().language
  },
  methods: {
    async setData() {
    // 총계를 내기 위하여, 해당 data를 한번 가공함
      await this.$getApi('/api/charger/dashboard/chargers/failure')
        .then(response => {
          this.brokenStatusList = response.data
        })
        .catch(error => { console.log(error) })

      await this.$getApi('/api/charger/dashboard/repair-request?periodStatus=TODAY')
        .then(response => {
          const data = response.data
          console.log(data)
          this.repairStatus = response.data
          this.repairStatus.unprocessed = data.assignment + data.delay + data.repair + data.visit + data.revisit
        })
        .catch(error => { console.log(error) })

      await this.$getApi('/api/charger/dashboard/repair-request/by-region?periodStatus=TODAY')
        .then(response => { this.areaRequestStatus = response.data })
        .catch(error => { console.log(error) })

      await this.$getApi(`/api/charger/dashboard/chargers/operation/area?language=${this.language}`)
        .then(response => { this.chargerStatusList = response.data })
        .catch(error => { console.log(error) })
    },
    async changeRepairStatus(day) {
      await this.$getApi(`/api/charger/dashboard/repair-request?periodStatus=${day}`)
        .then(response => {
          const data = response.data
          console.log(data)
          this.repairStatus = response.data
          this.repairStatus.unprocessed = data.assignment + data.delay + data.repair + data.visit + data.revisit
        })
        .catch(error => { console.log(error) })
    },
    async changeAreaRequestStatus(day) {
      await this.$getApi(`/api/charger/dashboard/repair-request/by-region?periodStatus=${day}`)
        .then(response => { this.areaRequestStatus = response.data })
        .catch(error => { console.log(error) })
    }
  }
}
</script>
<style scoped>

</style>
