import { createRouter, createWebHistory } from 'vue-router'
import ChargerDashBoard from '../views/charger/dashboard/AdminDashBoardView.vue'
import HADashBoard from '../views/appliance/dashboard/AdminDashBoardView.vue'
// import store from '@/store'

const routes = [
  {
    // 충전기 대시보드
    path: '/dashboard',
    name: '충전기 대시보드',
    // 해당 url에서 표시될 컴포넌트
    component: ChargerDashBoard
  },
  {
    // 가전 대시보드
    path: '/ha-dashboard',
    name: '가전 대시보드',
    component: HADashBoard
  },
  {
    path: '/callDashboard',
    name: 'callDashboard',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "callDashboard" */ '../views/charger/dashboard/CallDashBoardView.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/login/LoginView.vue')
  },
  {
    path: '/redirect',
    redirect: 'login'
  },
  {
    path: '/admin-register',
    name: '관리자 등록 팝업',
    component: () => import(/* webpackChunkName: "adminRegister" */ '../views/charger/system/popup/AdminRegister.vue')
  },
  {
    path: '/admin-team-register',
    name: '관리자 소속 등록 팝업',
    component: () => import(/* webpackChunkName: "adminTeamRegister" */ '../views/charger/system/popup/AdminTeamRegister.vue')
  },
  {
    path: '/repair-staff-register',
    name: '수리 직원 등록 팝업',
    component: () => import(/* webpackChunkName: "repairStaffRegister" */ '../views/charger/repairManagement/popup/RepairStaffRegister.vue')
  },
  {
    path: '/repair-staff-team-register',
    name: '수리 직원 소속 등록 팝업',
    component: () => import(/* webpackChunkName: "repairStaffTeamRegister" */ '../views/charger/repairManagement/popup/RepairStaffTeamRegister.vue')
  },
  {
    path: '/customer-history',
    name: '민원 고객 수리 요청 이력',
    component: () => import(/* webpackChunkName: "customerHistory" */ '../views/charger/repairRequest/popup/CustomerHistory.vue')
  },
  {
    path: '/service-company-register',
    name: '고객 등록 팝업',
    component: () => import(/* webpackChunkName: "serviceCompanyRegister" */ '../views/charger/infra/popup/ServiceCompanyRegister.vue')
  },
  {
    path: '/station-register',
    name: '충전소 등록 팝업',
    component: () => import(/* webpackChunkName: "stationRegister" */ '../views/charger/infra/popup/StationRegister.vue')
  },
  {
    path: '/charger-register',
    name: '충전기 등록 팝업',
    component: () => import(/* webpackChunkName: "chargerRegister" */ '../views/charger/infra/popup/ChargerRegister.vue')
  },
  {
    path: '/charger-model-register',
    name: '충전기모델 등록 팝업',
    component: () => import(/* webpackChunkName: "chargerModelRegister" */ '../views/charger/infra/popup/ChargerModelRegister.vue')
  },
  {
    path: '/charger-part-register',
    name: '충전기 부품 등록 팝업',
    component: () => import(/* webpackChunkName: "chargerPartRegister" */ '../views/charger/infra/popup/ChargerPartRegister.vue')
  },
  {
    path: '/repair-request-history-detail',
    name: '수리 요청 이력 상세',
    component: () => import(/* webpackChunkName: "repairRequestHistoryDetail" */ '../views/charger/repairRequest/RepairRequestHistoryDetail.vue')
  },
  {
    path: '/repair-request-history-detail/:id/:partnerId',
    name: '수리 요청 이력 상세',
    component: () => import(/* webpackChunkName: "repairRequestHistoryDetail" */ '../views/charger/repairRequest/RepairRequestHistoryDetail.vue')
  },
  {
    path: '/repair-request-management-detail',
    name: '수리 요청 관리 상세',
    component: () => import(/* webpackChunkName: "repairRequestManagementDetail" */ '../views/charger/repairManagement/RepairRequestManagementDetail.vue')
  },
  {
    path: '/repair-request-management-detail/:id/:partnerId',
    name: '수리 요청 관리 상세',
    component: () => import(/* webpackChunkName: "repairRequestManagementDetail" */ '../views/charger/repairManagement/RepairRequestManagementDetail.vue')
  },
  {
    path: '/repair-completed-detail',
    name: '수리 완료 내역 조회',
    component: () => import(/* webpackChunkName: "repairCompletedDetail" */ '../views/charger/repairManagement/RepairCompletedDetail.vue')
  },
  {
    path: '/repair-completed-detail/:id',
    name: '수리 완료 내역 조회',
    component: () => import(/* webpackChunkName: "repairCompletedDetail" */ '../views/charger/repairManagement/RepairCompletedDetail.vue')
  },
  {
    path: '/errorPage',
    name: 'errorPage',
    component: () => import(/* webpackChunkName: "errorPage" */ '../views/error/ErrorPage.vue')
  },
  {
    path: '/repair-request-non-public-registration',
    name: '수리 요청 등록(비공용)',
    component: () => import(/* webpackChunkName: "RepairRequestNonPublicRegistration" */ '../views/charger/repairRequest/RepairRequestNonPublicRegistration.vue'),
    meta: { authorization: [] }
  },
  {
    path: '/repair-request-registration',
    name: '수리 요청 등록(공용)',
    component: () => import(/* webpackChunkName: "repairRequestRegistration" */ '../views/charger/repairRequest/RepairRequestRegistration.vue'),
    meta: { authorization: [] }
  },
  {
    path: '/repair-request-history',
    name: '수리 요청 이력',
    component: () => import(/* webpackChunkName: "repairRequestHistory" */ '../views/charger/repairRequest/RepairRequestHistory.vue')
  },
  {
    path: '/customer-management',
    name: '민원 고객 관리',
    component: () => import(/* webpackChunkName: "customerManagement" */ '../views/charger/repairRequest/CustomerManagement.vue')
  },
  {
    path: '/repair-request-management',
    name: '수리 요청 관리',
    component: () => import(/* webpackChunkName: "repairRequestManagement" */ '../views/charger/repairManagement/RepairRequestManagement.vue')
  },
  {
    path: '/repair-staff-team-management',
    name: '수리 직원 소속 관리',
    component: () => import(/* webpackChunkName: "repairStaffTeamManagement" */ '../views/charger/repairManagement/RepairStaffTeamManagement.vue')
  },
  {
    path: '/repair-staff-management',
    name: '수리 직원 관리',
    component: () => import(/* webpackChunkName: "repairStaffManagement" */ '../views/charger/repairManagement/RepairStaffManagement.vue')
  },
  {
    path: '/service-company-management',
    name: '고객사 관리',
    component: () => import(/* webpackChunkName: "serviceCompanyManagement" */ '../views/charger/infra/ServiceCompanyManagement.vue')
  },
  {
    path: '/station-management',
    name: '충전소 관리',
    component: () => import(/* webpackChunkName: "stationManagement" */ '../views/charger/infra/StationManagement.vue')
  },
  {
    path: '/charger-management',
    name: '충전기 관리',
    component: () => import(/* webpackChunkName: "chargerManagement" */ '../views/charger/infra/ChargerManagement.vue')
  },
  {
    path: '/charger-model-management',
    name: '충전기 모델 관리',
    component: () => import(/* webpackChunkName: "chargerModelManagement" */ '../views/charger/infra/ChargerModelManagement.vue')
  },
  {
    path: '/charger-part-management',
    name: '충전기 부품 관리',
    component: () => import(/* webpackChunkName: "chargerPartManagement" */ '../views/charger/infra/ChargerPartManagement.vue')
  },
  {
    path: '/code-management',
    name: '코드 관리',
    component: () => import(/* webpackChunkName: "codeManagement" */ '../views/charger/system/CodeManagement.vue')
  },
  {
    path: '/repair-request-type-management',
    name: '요청 유형 관리',
    component: () => import(/* webpackChunkName: "repairRequestTypeManagement" */ '../views/charger/system/RepairRequestTypeManagement.vue')
  },
  {
    path: '/broken-type-management',
    name: '수리 유형 관리',
    component: () => import(/* webpackChunkName: "brokenTypeManagement" */ '../views/charger/system/BrokenTypeManagement.vue')
  },
  {
    path: '/part-type-management',
    name: '부품 유형 관리',
    component: () => import(/* webpackChunkName: "partTypeManagement" */ '../views/charger/system/PartTypeManagement.vue')
  },
  {
    path: '/menu-authority-management',
    name: '권한별 메뉴 관리',
    component: () => import(/* webpackChunkName: "menuAuthorityManagement" */ '../views/charger/system/MenuAuthorityManagement.vue')
  },
  {
    path: '/notification-setting-management',
    name: '충전기 알림',
    component: () => import(/* webpackChunkName: "chargerNotification" */ '../views/charger/system/NotificationSettingManagement.vue')
  },
  {
    path: '/admin-management',
    name: '관리자관리',
    component: () => import(/* webpackChunkName: "adminManagement" */ '../views/charger/system/AdminManagement.vue')
  },
  {
    path: '/admin-team-management',
    name: '관리자소속관리',
    component: () => import(/* webpackChunkName: "adminTeamManagement" */ '../views/charger/system/AdminTeamManagement.vue')
  },
  {
    path: '/partner-alloc-management',
    name: '파트너등록관리',
    component: () => import(/* webpackChunkName: "partnerAllocManagement" */ '../views/charger/partner/PartnerAllocManagement.vue')
  },
  {
    path: '/partner-register',
    name: '파트너 등록 팝업',
    component: () => import(/* webpackChunkName: "partnerRegister" */ '../views/charger/partner/popup/PartnerRegister.vue')
  },
  {
    path: '/admin-team-partner-relation-management',
    name: '관리자 소속 파트너 배정 관리',
    component: () => import('../views/charger/partner/AdminTeamPartnerRelationManagement.vue')
  },
  {
    path: '/admin-team-partner-relation-register',
    name: '관리자 소속 파트너 배정 등록 팝업',
    component: () => import(/* webpackChunkName: "partnerRegister" */ '../views/charger/partner/popup/AdminTeamPartnerRelationRegister.vue')
  },

  {
    path: '/staff-team-partner-relation-management',
    name: '수리 직원 소속 파트너 배정 관리',
    component: () => import('../views/charger/partner/StaffTeamPartnerRelationManagement.vue')
  },
  {
    path: '/staff-team-partner-register',
    name: '수리 직원 소속 파트너 배정 등록 팝업',
    component: () => import(/* webpackChunkName: "partnerRegister" */ '../views/charger/partner/popup/StaffTeamPartnerRegister.vue')
  },

  // 가전 시작
  {
    path: '/ha-admin-register',
    name: '가전 관리자 등록 팝업',
    component: () => import(/* webpackChunkName: "HAadminRegister" */ '../views/appliance/system/popup/AdminRegister.vue')
  },
  {
    path: '/ha-admin-team-register',
    name: '가전 관리자 소속 등록 팝업',
    component: () => import(/* webpackChunkName: "HAadminTeamRegister" */ '../views/appliance/system/popup/AdminTeamRegister.vue')
  },
  {
    path: '/ha-repair-staff-register',
    name: '가전 수리 직원 등록 팝업',
    component: () => import(/* webpackChunkName: "HArepairStaffRegister" */ '../views/appliance/repairManagement/popup/RepairStaffRegister.vue')
  },
  {
    path: '/ha-repair-staff-team-register',
    name: '가전 수리 직원 소속 등록 팝업',
    component: () => import(/* webpackChunkName: "HArepairStaffTeamRegister" */ '../views/appliance/repairManagement/popup/RepairStaffTeamRegister.vue')
  },
  {
    path: '/ha-customer-history',
    name: '가전 민원 고객 수리 요청 이력',
    component: () => import(/* webpackChunkName: "HAcustomerHistory" */ '../views/appliance/repairRequest/popup/CustomerHistory.vue')
  },
  {
    path: '/ha-service-company-register',
    name: '가전 고객 등록 팝업',
    component: () => import(/* webpackChunkName: "HAserviceCompanyRegister" */ '../views/appliance/infra/popup/ServiceCompanyRegister.vue')
  },
  {
    path: '/ha-product-register',
    name: '가전 등록 팝업',
    component: () => import(/* webpackChunkName: "HAproductRegister" */ '../views/appliance/infra/popup/ProductRegister.vue')
  },
  {
    path: '/ha-product-model-register',
    name: '가전 제품군 등록 팝업',
    component: () => import(/* webpackChunkName: "HAproductModelRegister" */ '../views/appliance/infra/popup/ProductModelRegister.vue')
  },
  {
    path: '/ha-product-part-register',
    name: '가전 부품 등록 팝업',
    component: () => import(/* webpackChunkName: "HAproductPartRegister" */ '../views/appliance/infra/popup/ProductPartRegister.vue')
  },
  {
    path: '/ha-repair-request-history-detail',
    name: '가전 수리 요청 이력 상세',
    component: () => import(/* webpackChunkName: "HArepairRequestHistoryDetail" */ '../views/appliance/repairRequest/RepairRequestHistoryDetail.vue')
  },
  {
    path: '/ha-repair-request-history-detail/:id/:partnerId',
    name: '가전 수리 요청 이력 상세',
    component: () => import(/* webpackChunkName: "HArepairRequestHistoryDetail" */ '../views/appliance/repairRequest/RepairRequestHistoryDetail.vue')
  },
  {
    path: '/ha-repair-request-management-detail',
    name: '가전 수리 요청 관리 상세',
    component: () => import(/* webpackChunkName: "HArepairRequestManagementDetail" */ '../views/appliance/repairManagement/RepairRequestManagementDetail.vue')
  },
  {
    path: '/ha-repair-request-management-detail/:id/:partnerId',
    name: '가전 수리 요청 관리 상세',
    component: () => import(/* webpackChunkName: "HArepairRequestManagementDetail" */ '../views/appliance/repairManagement/RepairRequestManagementDetail.vue')
  },
  {
    path: '/ha-repair-completed-detail',
    name: '가전 수리 완료 내역 조회',
    component: () => import(/* webpackChunkName: "HArepairCompletedDetail" */ '../views/appliance/repairManagement/RepairCompletedDetail.vue')
  },
  {
    path: '/ha-repair-completed-detail/:id',
    name: '가전 수리 완료 내역 조회',
    component: () => import(/* webpackChunkName: "HArepairCompletedDetail" */ '../views/appliance/repairManagement/RepairCompletedDetail.vue')
  },
  {
    path: '/ha-repair-request-registration',
    name: '가전 수리 요청 등록',
    component: () => import(/* webpackChunkName: "HArepairRequestRegistration" */ '../views/appliance/repairRequest/RepairRequestRegistration.vue'),
    meta: { authorization: [] }
  },
  {
    path: '/ha-repair-request-history',
    name: '가전 수리 요청 이력',
    component: () => import(/* webpackChunkName: "HArepairRequestHistory" */ '../views/appliance/repairRequest/RepairRequestHistory.vue')
  },
  {
    path: '/ha-customer-management',
    name: '가전 민원 고객 관리',
    component: () => import(/* webpackChunkName: "HAcustomerManagement" */ '../views/appliance/repairRequest/CustomerManagement.vue')
  },
  {
    path: '/ha-repair-request-management',
    name: '가전 수리 요청 관리',
    component: () => import(/* webpackChunkName: "HArepairRequestManagement" */ '../views/appliance/repairManagement/RepairRequestManagement.vue')
  },
  {
    path: '/ha-repair-staff-team-management',
    name: '가전 수리 직원 소속 관리',
    component: () => import(/* webpackChunkName: "HArepairStaffTeamManagement" */ '../views/appliance/repairManagement/RepairStaffTeamManagement.vue')
  },
  {
    path: '/ha-repair-staff-management',
    name: '가전 수리 직원 관리',
    component: () => import(/* webpackChunkName: "HArepairStaffManagement" */ '../views/appliance/repairManagement/RepairStaffManagement.vue')
  },
  {
    path: '/ha-service-company-management',
    name: '가전 고객사 관리',
    component: () => import(/* webpackChunkName: "HAserviceCompanyManagement" */ '../views/appliance/infra/ServiceCompanyManagement.vue')
  },
  {
    path: '/ha-manufacturer-management',
    name: '제조사관리',
    component: () => import(/* webpackChunkName: "HAmanufacturerManagement" */ '../views/appliance/infra/ManufacturerManagement.vue')
  },
  {
    path: '/ha-product-model-management',
    name: '가전제품군관리',
    component: () => import(/* webpackChunkName: "HAproductModelManagement" */ '../views/appliance/infra/ProductModelManagement.vue')
  },
  {
    path: '/ha-product-management',
    name: '가전모델관리',
    component: () => import(/* webpackChunkName: "HAproductManagement" */ '../views/appliance/infra/ProductManagement.vue')
  },
  {
    path: '/ha-product-part-management',
    name: '가전부품관리',
    component: () => import(/* webpackChunkName: "HAproductPartManagement" */ '../views/appliance/infra/ProductPartManagement.vue')
  },
  {
    path: '/ha-code-management',
    name: '가전 코드 관리',
    component: () => import(/* webpackChunkName: "HAcodeManagement" */ '../views/appliance/system/CodeManagement.vue')
  },
  {
    path: '/ha-repair-request-type-management',
    name: '가전 요청 유형 관리',
    component: () => import(/* webpackChunkName: "HArepairRequestTypeManagement" */ '../views/appliance/system/RepairRequestTypeManagement.vue')
  },
  {
    path: '/ha-broken-type-management',
    name: '가전 수리 유형 관리',
    component: () => import(/* webpackChunkName: "HAbrokenTypeManagement" */ '../views/appliance/system/BrokenTypeManagement.vue')
  },
  {
    path: '/ha-part-type-management',
    name: '가전 부품 유형 관리',
    component: () => import(/* webpackChunkName: "HApartTypeManagement" */ '../views/appliance/system/PartTypeManagement.vue')
  },
  {
    path: '/ha-menu-authority-management',
    name: '가전 권한별 메뉴 관리',
    component: () => import(/* webpackChunkName: "HAmenuAuthorityManagement" */ '../views/appliance/system/MenuAuthorityManagement.vue')
  },
  {
    path: '/ha-notification-setting-management',
    name: '가전 알림 설정 관리',
    component: () => import(/* webpackChunkName: "homeNotificationSettingManagement" */ '../views/appliance/system/NotificationSettingManagement.vue')
  },

  {
    path: '/ha-admin-management',
    name: '가전 관리자관리',
    component: () => import(/* webpackChunkName: "HAadminManagement" */ '../views/appliance/system/AdminManagement.vue')
  },
  {
    path: '/ha-admin-team-management',
    name: '가전 관리자소속관리',
    component: () => import(/* webpackChunkName: "HAadminTeamManagement" */ '../views/appliance/system/AdminTeamManagement.vue')
  },
  {
    path: '/ha-partner-alloc-management',
    name: '가전 파트너등록관리',
    component: () => import(/* webpackChunkName: "HApartnerAllocManagement" */ '../views/appliance/partner/PartnerAllocManagement.vue')
  },
  {
    path: '/ha-partner-register',
    name: '가전 파트너 등록 팝업',
    component: () => import(/* webpackChunkName: "HApartnerRegister" */ '../views/appliance/partner/popup/PartnerRegister.vue')
  },
  {
    path: '/ha-visit-difficulty-type-management',
    name: '급지 관리',
    component: () => import(/* webpackChunkName: "HAVisitDifficultyTypeManagement" */ '../views/appliance/repairManagement/VisitDifficultyTypeManagement.vue')
  },
  {
    path: '/ha-repair-fee-update',
    name: '비용 변경',
    component: () => import('../views/appliance/repairManagement/popup/RepairFeeUpdate.vue')
  },
  {
    path: '/ha-admin-team-partner-relation-management',
    name: '관리자소속파트너배정관리',
    component: () => import('../views/appliance/partner/AdminTeamPartnerRelationManagement.vue')
  },
  {
    path: '/ha-admin-team-partner-relation-register',
    name: '관리자소속파트너배정등록팝업',
    component: () => import('../views/appliance/partner/popup/AdminTeamPartnerRelationRegister.vue')
  },
  {
    path: '/ha-staff-team-partner-relation-management',
    name: '수리기사소속파트너배정관리',
    component: () => import('../views/appliance/partner/StaffTeamPartnerRelationManagement.vue')
  },
  {
    path: '/ha-staff-team-partner-relation-register',
    name: '수리직원소속파트너배정등록팝업',
    component: () => import('../views/appliance/partner/popup/StaffTeamPartnerRelationRegister.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// router.beforeEach(async (to, from) => {
//
// })

// router.beforeEach((to, from, next) => {

// })

export default router
