<template>
  <div class="header_bottom">
    <nav id="gnb">
      <ul class="clearfix" id="main_menu_ul">
        <li v-for="item in items" :key="item.menuWebCode" @mouseover="expandSubMenu(item)" @mouseleave="collapseSubMenu(item)" :class="{'on':item.isExpanded}" class="has-2dep">
          <a href="#"><span>{{ item.name }}</span></a>
          <div class="gnb-2dep" :class="{'open':item.isExpanded}">
            <ul>
              <li v-for="childMenuList in item.childMenuList" :key="childMenuList.menuWebCode">
                <a @click="reToken($event, childMenuList)"><span>{{ childMenuList.name }}</span></a>
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
import store from '@/store'
import axios from 'axios'
import { mapGetters } from 'vuex'

axios.defaults.withCredentials = true

export default {
  computed: {
    ...mapGetters(['serviceType']),
    serviceTypeMapped() {
      const serviceType = this.serviceType
      // serviceType 값에 따라 대체할 값을 반환합니다.
      return serviceType === 'C' ? 'charger' : (serviceType === 'H' ? 'appliance' : serviceType)
    }
  },
  data() {
    return {
      items: [],
      loginId: ''
    }
  },
  mounted () {
    const authToken = sessionStorage.getItem('authToken')

    if (authToken !== null) {
      this.loginId = store.state.user.id.loginId
      this.menuList()
    }
  },
  methods: {
    expandSubMenu(item) {
      item.isExpanded = true
    },
    collapseSubMenu(item) {
      item.isExpanded = false
    },
    async menuList() {
      await this.$getApi(`/api/${this.serviceTypeMapped}/auth/menus`)
        .then(response => {
          this.items = response.data
        })
    },
    async reToken(event, childMenuList) {
      const vm = this
      const urlPath = childMenuList.urlPath

      event.preventDefault()

      const headers = {
        Authorization: store.state.user.id.authToken
      }
      await axios({
        url: `/api/${this.serviceTypeMapped}/auth/token/refresh`,
        method: 'post',
        headers: headers
      })
        .then((res) => {
          const authToken = res.data
          this.$store.commit('user', { loginId: this.loginId, authToken })
          sessionStorage.setItem('authToken', authToken)

          const newTabUrl = [
            '/ha-repair-request-registration', '/ha-repair-request-history',
            '/ha-repair-request-management', '/ha-repair-staff-team-management', '/ha-repair-staff-management', '/ha-visit-difficulty-type-management'
          ]
          if (newTabUrl.includes(urlPath)) {
            window.open(urlPath, '_blank')
          } else {
            // 토큰 갱신 후 페이지 이동
            this.$router.push(urlPath)
          }
        })
        .catch(error => {
          if (error.response.status === 403) {
            this.$store.commit('user', {})
            sessionStorage.removeItem('authToken')
            alert(this.$t('message.session'))
            const popupPaths = [
              '/admin/register',
              '/admin/team/register',
              '/repair/staff/register',
              '/repair/staff/team/register',
              '/customer/history',
              '/station/register',
              '/charger/register',
              '/charger/model/register',
              '/charger/part/register'
            ]
            console.log(this.routePath)
            if (popupPaths.includes(this.routePath)) {
              window.close()
            } else {
              vm.$router.push({ path: '/login' })
            }
          }
        })
    }
  }
}
</script>
