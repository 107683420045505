<template>
  <div>
    <nav class="appHeader">
      <app-header v-if="shouldRenderHeader" />
    </nav>
    <router-view/>
  </div>
</template>

<script>
import AppHeader from '@/components/layouts/AppHeader.vue'

import 'ag-grid-community/styles//ag-grid.css'
import 'ag-grid-community/styles//ag-theme-alpine.css'

export default {
  components: {
    AppHeader
  },
  created () {
    const vm = this

    const authToken = sessionStorage.getItem('authToken')

    if (!authToken) {
      vm.$router.push({ path: '/redirect' })
    } else {
      this.language = this.$tokenDecode().language
      this.changeLocale(this.language)
    }
  },
  data() {
    return {
      language: 'ko'
    }
  },
  computed: {
    user() {
      return this.store.state.user
    },
    shouldRenderHeader() {
      const excludedRoutes = [
        '/login',
        '/errorPage',
        '/admin-register',
        '/admin-team-register',
        '/repair-staff-register',
        '/repair-staff-team-register',
        '/customer-history',
        '/station-register',
        '/charger-register',
        '/charger-model-register',
        '/charger-part-register',
        '/repair-request-management-detail',
        '/repair-completed-detail',
        '/ha-admin-register',
        '/ha-admin-team-register',
        '/ha-repair-staff-register',
        '/ha-repair-staff-team-register',
        '/ha-customer-history',
        '/ha-station-register',
        '/ha-product-register',
        '/ha-product-model-register',
        '/ha-product-part-register',
        '/ha-repair-request-management-detail',
        '/ha-repair-completed-detail',
        '/ha-repair-fee-update',
        '/repair-staff-partner-register',
        '/admin-team-partner-relation-register',
        '/staff-team-partner-register',
        '/ha-staff-team-partner-relation-register',
        '/ha-repair-staff-team-partner-relation-register'

      ]
      return !excludedRoutes.includes(this.$route.path)
    }
  },
  methods: {
    changeLocale(language) {
      this.$i18n.locale = language
    }
  }
}
</script>

<style>
@import '@/assets/css/common.css';
@import '@/assets/css/style.css';
.ag-theme-alpine {
  --ag-grid-size: 4px;
  --ag-list-item-height: 20px;
}
.ag-header-viewport {
  background: #e8e9eb;
}
.ag-header-cell ag-focus-managed {
  text-align: center;
}
.ag-paging-panel {
  text-align: center;
}
</style>
