import { createStore } from 'vuex'
import persistedstate from 'vuex-persistedstate'
import Cookies from 'js-cookie'

const store = createStore({
  state() {
    return {
      isLogin: false,
      user: {},
      serviceType: Cookies.get('serviceType') ? Cookies.get('serviceType') : 'C', // 기본값 설정
      popupSaveButton: {
        adminUserRegisterButton: false,
        adminCompanyRegisterButton: false,
        repairStaffTeamRegisterButton: false,
        repairStaffRegisterButton: false,
        stationRegisterButton: false,
        chargerRegisterButton: false,
        chargerModelRegisterButton: false,
        chargerPartRegisterButton: false,
        serviceCompanyRegisterButton: false,
        productRegisterButton: false,
        adminTeamPartnerRelationRegisterButton: false,
        repairRequestMemoButton: false
      },
      searchCriteria: {
        RepairRequestManagement: {
          fromDate: new Date(),
          toDate: new Date(),
          type: '', // 기본값 설정
          text: '',
          requestStatus: '', // 기본값 설정
          areaMainCode: '',
          areaSubCode: '',
          page: '',
          size: ''
        },
        HARepairRequestManagement: {
          fromDate: new Date(),
          toDate: new Date(),
          type: '', // 기본값 설정
          text: '',
          requestStatus: '', // 기본값 설정
          areaMainCode: '',
          areaSubCode: '',
          page: '',
          size: ''
        },
        RepairRequestHistory: {
          fromDate: new Date(),
          toDate: new Date(),
          type: '', // 기본값 설정
          text: '',
          requestStatus: '', // 기본값 설정
          areaMainCode: '',
          areaSubCode: '',
          page: '',
          size: ''
        },
        HARepairRequestHistory: {
          fromDate: new Date(),
          toDate: new Date(),
          type: '', // 기본값 설정
          text: '',
          requestStatus: '', // 기본값 설정
          areaMainCode: '',
          areaSubCode: '',
          page: '',
          size: ''
        }
      },
      adminTeamType: ''
    }
  },
  plugins: [
    persistedstate({ paths: ['user', 'serviceType'] })
  ],
  getters: {
    getPopupData(state) {
      return state.popupSaveButton.adminUserRegisterButton
    },
    // serviceType를 getter로 추가
    serviceType(state) {
      return state.serviceType
    },
    getSearchCriteria: state => module => state.searchCriteria[module]
  },
  mutations: {
    isLogin(state, data) {
      state.isLogin = data
    },
    async fnSearch() {
      if (this.fromDate !== null) {
        this.search.requestFrom = this.formatDate(this.fromDate)
      } else {
        this.search.requestFrom = ''
      }
      if (this.toDate !== null) {
        this.search.requestTo = this.formatDate(this.toDate)
      } else {
        this.search.requestTo = ''
      }
      const queryString = `/api/charger/repair-management?page=${this.currentPage}&size=${this.gridRow}&` +
        `requestFrom=${this.search.requestFrom}&requestTo=${this.search.requestTo}&` +
        `${this.type}=${this.search.text}&requestStatus=${this.search.requestStatus}&` +
        `areaMainCode=${this.search.areaMainCode}&areaSubCode=${this.search.areaSubCode}&` +
        `partnerId=${this.search.partnerId}`
      await this.$getApi(`${queryString}`)
        .then(response => {
          this.rowData.value = response.data.content
          this.totalElements = response.data.totalElements
          this.totalPages = response.data.totalPages
          this.currentPage = response.data.pageable.pageNumber
        })
        .catch(error => { console.log(error) })
    },
    user(state, data) {
      state.user.id = data
    },
    adminTeamType(state, data) {
      state.adminTeamType = data
    },
    setPopupData(state, data) {
      Object.assign(state.popupSaveButton, data)
    },
    setServiceType(state, type) {
      state.serviceType = type
    },
    updateSearchCriteria(state, { module, criteria }) {
      state.searchCriteria[module] = criteria
    }
  },
  actions: {
    setServiceType({ commit }, type) {
      commit('setServiceType', type)
    },
    setSearchCriteria({ commit }, { module, criteria }) {
      commit('updateSearchCriteria', { module, criteria })
    },
    setAdminTeamType({ commit }, type) {
      commit('adminTeamType', type)
    }
  },
  modules: {
  }
})

export default store
