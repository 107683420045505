<template>
  <div id="header">
    <div class="header_top">
      <h1>
        <a href=""><img id="headerLogo" src="@/assets/images/common/dblogo.png"/></a>
      </h1>
      <div class="top_area">
        <p class="login_date"><span id="strToday"></span></p>
        <ul class="clearfix">
          <li><p class="service_type">{{ serviceTypeLabel }}</p></li>
          <li>
            <a @click="logout()"><img src="@/assets/images/common/header_ico_logout.png" alt="로그아웃"/> {{ $t('login.logout') }}</a>
          </li>
          <li>
            <a href="#myPage" data-bs-toggle="modal">
              <img src="@/assets/images/common/header_ico_modify.png" alt="정보수정"/> {{ $t('login.modifyingInformation') }}
            </a>
          </li>
        </ul>
      </div>
    </div>
    <MyPage/>
    <header-menu/>
  </div>
</template>
<script>
import HeaderMenu from '@/components/layouts/HeaderMenu.vue'
import MyPage from '@/components/login/MyPage.vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    HeaderMenu,
    MyPage
  },
  computed: {
    ...mapGetters(['serviceType']),
    serviceTypeLabel() {
      // serviceType에 따라 다른 라벨 반환
      if (this.serviceType === 'C') {
        return this.$t('serviceType.charger')
      } else if (this.serviceType === 'H') {
        return this.$t('serviceType.appliance')
      }
      return ''
    }
  },
  data() {
    return {
      popupTitle: '개인 정보 수정'
    }
  },
  methods: {
    async logout() {
      const vm = this
      const serviceType = this.$store.getters.serviceType
      let logoutUrl = '/api/auth/logout'
      if (serviceType === 'C') {
        logoutUrl = '/api/charger/auth/logout'
      } else if (serviceType === 'H') {
        logoutUrl = '/api/appliance/auth/logout'
      }

      await this.$api(logoutUrl, 'post')
        .then(response => {
          this.$store.commit('user', {})
          sessionStorage.removeItem('authToken')
          alert(this.$t('message.logout'))
          vm.$router.push({ path: '/login' })
        })
        .catch(error => { console.log(error) })
    }
  }
}
</script>
<style scoped>
.service_type {
  font-weight: bold;
  margin-right: 10px; /* 원하는 간격으로 조절하세요. */
}
/* 이 CSS는 해당 요소에 직접 스타일을 적용하는 방법입니다. */
a {
  cursor: pointer; /* 마우스 커서를 손가락으로 변경합니다. */
}
</style>
