<template>
  <div id="container">
    <article class="titleWrap" id="contentTopWrap">
      <div id="contentsGroup">
        <h2>{{ $t('menu.dashboard') }}</h2>
      </div>
    </article>
    <div class="dashboard_flex">
      <div class="dahsboard_flex_col dahsboard_flex_col_10">
        <div class="title_btn clearfix">
          <!-- A/S현황 -->
          <h3 class="title fl_l">{{ $t('dashboard.asStatus') }} </h3>
          <div class="btn_wrap fl_r mb15">
                <td data-label="설치일자" class="date-picker-container">
                  <!-- 요청일 -->
                  <th style="margin-right: 4px;"> {{ $t('search.requestDate') }} : </th>
                  <calendar v-model="fromDate" placeholder= "yyyy-MM-dd" pattern="yyyy-MM-dd" />
                  <span class="date-separator">~</span>
                  <calendar style="margin-right: 4px;" v-model="toDate" placeholder= "yyyy-MM-dd" pattern="yyyy-MM-dd" />
                  <button style="margin-right: 50px;" @click="fnSearch" class="point_btn search_btn">{{ $t('button.search') }}</button>
                  <button @click="changeASStatus('TODAY')" class="point_btn">{{ $t('button.today') }}</button>
                  <button @click="changeASStatus('WEEK')" class="point_btn">{{ $t('button.lastWeek') }}</button>
                  <button @click="changeASStatus('MONTH')" class="point_btn">{{ $t('button.lastMonth') }}</button>
                  <button @click="changeASStatus('YEAR')" class="point_btn">{{ $t('button.lastYear') }}</button>
                </td>
          </div>
        </div>
        <div class="dashboard_inner">
          <div class="flex_box">
            <!-- 총계 -->
            <p class="box_tit">{{ $t('dashboard.summation') }}</p>
            <div class="flex_box_num"><strong>{{ repairStatus.summation }}</strong></div>
          </div>
          <div class="flex_box">
            <!-- 접수 -->
            <p class="box_tit">{{ $t('dashboard.request') }}</p>
            <div class="flex_box_num"><strong>{{ repairStatus.request }}</strong></div>
          </div>
          <div class="flex_box">
            <!-- 배정 -->
            <p class="box_tit">{{ $t('dashboard.assign') }}</p>
            <div class="flex_box_num"><strong>{{ repairStatus.assignment }}</strong></div>
          </div>
          <div class="flex_box">
            <!-- 방문 예정 -->
            <p class="box_tit">{{ $t('dashboard.toVisited') }}</p>
            <div class="flex_box_num"><strong>{{ repairStatus.visit }}</strong></div>
          </div>
          <div class="flex_box red">
            <!-- 지연 -->
            <p class="box_tit">{{ $t('dashboard.delay') }}</p>
            <div class="flex_box_num"><strong>{{ repairStatus.delay }}</strong></div>
          </div>
          <div class="flex_box">
            <!-- 재방문 -->
            <p class="box_tit">{{ $t('dashboard.reVisited') }} {{ $t('dashboard.schedule') }}</p>
            <div class="flex_box_num"><strong>{{ repairStatus.revisit }}</strong></div>
          </div>
          <div class="flex_box">
            <!-- 완료 -->
            <p class="box_tit">{{ $t('dashboard.completion') }}</p>
            <div class="flex_box_num"><strong>{{ repairStatus.complete }}</strong></div>
          </div>
          <div class="flex_box big">
            <!-- 수리 진행중 합계 -->
            <p class="box_tit">{{ $t('dashboard.fix') }} {{ $t('dashboard.ongoing') }} {{ $t('dashboard.sum') }}</p>
            <div class="flex_box_num"><strong>{{ repairStatus.repair }}</strong></div>
          </div>
        </div>
      </div>
      <div class="dahsboard_flex_col dahsboard_flex_col_10">
        <div class="title_btn clearfix" style="margin-bottom: 10px;">
          <!-- 서비스품질현황 -->
          <h3 class="title fl_l">{{ $t('dashboard.serviceQualityStatus') }}</h3>
          <div class="btn_wrap fl_r ">
            <td data-label="설치일자" class="date-picker-container">
                  <!-- 요청일 -->
                  <th style="margin-right: 4px;">{{ $t('search.requestDate') }} : </th>
                  <calendar v-model="serviceFromDate" placeholder= "yyyy-MM-dd" pattern="yyyy-MM-dd" />
                  <span class="date-separator">~</span>
                  <calendar style="margin-right: 4px;" v-model="serviceToDate" placeholder= "yyyy-MM-dd" pattern="yyyy-MM-dd" />
                  <button  style="margin-right: 50px;" @click="serviceFnSearch" class="point_btn search_btn">{{ $t('button.search') }}</button>

            <button @click="changeServiceQualityStatus('TODAY')" class="point_btn">{{ $t('button.today') }}</button>
            <button @click="changeServiceQualityStatus('WEEK')" class="point_btn">{{ $t('button.lastWeek') }}</button>
            <button @click="changeServiceQualityStatus('MONTH')" class="point_btn">{{ $t('button.lastMonth') }}</button>
            <button @click="changeServiceQualityStatus('YEAR')" class="point_btn">{{ $t('button.lastYear') }}</button>
            </td>
          </div>
        </div>
        <div class="scroll">
          <table class="tableA">
            <thead class="custom-thead">
            <tr>
              <th rowspan="2" class="custom-th">항목</th>
              <th colspan="5" class="custom-th1">처리소요일수</th>
              <th rowspan="2" class="custom-th1" style="border-bottom: 1px solid #b9baba !important;">미처리율<br/>(미결)</th>
              <th rowspan="2" class="custom-th1" style="border-bottom: 1px solid #b9baba !important;">재수리율<br/>(2개월 이내)</th>
              <th rowspan="2" class="custom-th1" style="border-bottom: 1px solid #b9baba !important; border-right: 1px solid #b9baba !important;">보증경과<br/>무상처리율</th>
            </tr>
            <tr>
              <th class="custom-th1" style="border-bottom: 1px solid #b9baba !important;">총계</th>
              <th class="custom-th1" style="border-bottom: 1px solid #b9baba !important;">1일 이내</th>
              <th class="custom-th1" style="border-bottom: 1px solid #b9baba !important;">3일 이내</th>
              <th class="custom-th1" style="border-bottom: 1px solid #b9baba !important;">3일 경과</th>
              <th class="custom-th1" style="border-bottom: 1px solid #b9baba !important;">7일 경과</th>
            </tr>
            </thead>
          </table>
          <table class="tableA">
            <tbody>
              <tr v-for="quality in serviceQuality.productModelServiceQualitys" :key="quality.productModelId">
                <td>{{ quality.productModelName }}</td>
                <td>{{ quality.summation }}</td>
                <td>{{ quality.withinOneDay }}</td>
                <td>{{ quality.withinThreeDays }}</td>
                <td>{{ quality.threeDaysPassed }}</td>
                <td>{{ quality.sevenDaysPassed }}</td>
                <td>{{ quality.unprocessedRate }}%</td>
                <td>{{ quality.reoccurrenceRate }}%</td>
                <td>{{ quality.outOfWarrantyFreeProcessingRate }}%</td>
              </tr>
            </tbody>
          </table>
          <table class="tableA">
            <tbody>
            <tr v-for="quality in serviceQuality.partnerServiceQualitys" :key="quality.partnerId">
              <td>{{ quality.partnerName }}</td>
              <td>{{ quality.summation }}</td>
              <td>{{ quality.withinOneDay }}</td>
              <td>{{ quality.withinThreeDays }}</td>
              <td>{{ quality.threeDaysPassed }}</td>
              <td>{{ quality.sevenDaysPassed }}</td>
              <td>{{ quality.unprocessedRate }}%</td>
              <td>{{ quality.reoccurrenceRate }}%</td>
              <td>{{ quality.outOfWarrantyFreeProcessingRate }}%</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import Calendar from 'primevue/calendar'
import 'primevue/resources/themes/saga-blue/theme.css'
import 'primevue/resources/primevue.min.css'

export default {
  components: {
    calendar: Calendar
  },

  data() {
    return {
      repairStatus: {
        assignment: '0',
        complete: '0',
        delay: '0',
        repair: '0',
        request: '0',
        revisit: '0',
        visit: '0',
        summation: '0'
      },
      serviceQuality: {
        productModelServiceQualitys: [],
        partnerServiceQualitys: []
      },
      language: 'ko',
      day: 1,
      time: 1,
      fromDate: null,
      toDate: null,
      serviceFromDate: null,
      serviceToDate: null,
      search: {
        requestFrom: '',
        requestTo: '',
        serviceRequestFrom: '',
        serviceRequestTo: ''
      }
    }
  },
  computed: {
    ...mapGetters(['serviceType'])
  },
  mounted() {
    this.setData()

    this.language = this.$tokenDecode().language
  },
  methods: {
    async setData() {
      await this.$getApi('/api/appliance/dashboard/repair-request?periodStatus=TODAY')
        .then(response => {
          this.repairStatus = response.data
        })
        .catch(error => {
          console.log(error)
        })

      await this.$getApi(`/api/appliance/dashboard/service-quality?periodStatus=TODAY&serviceType=${this.serviceType}`)
        .then(response => {
          this.serviceQuality = response.data
        })
        .catch(error => {
          console.log(error)
        })
    },
    async fnSearch() {
      if (this.fromDate !== null) {
        this.search.requestFrom = this.formatDate(this.fromDate)
      } else {
        this.search.requestFrom = ''
      }
      if (this.toDate !== null) {
        this.search.requestTo = this.formatDate(this.toDate)
      } else {
        this.search.requestTo = ''
      }

      await this.$getApi(`/api/appliance/dashboard/repair-request/between?endDate=${this.search.requestTo}&startDate=${this.search.requestFrom}`)
        .then(response => {
          this.repairStatus = response.data
        }

        )
        .catch(error => { console.log(error) })
    },

    async serviceFnSearch() {
      if (this.serviceFromDate !== null) {
        this.search.serviceRequestFrom = this.formatDate(this.serviceFromDate)
      } else {
        this.search.serviceRequestFrom = ''
      }
      if (this.serviceToDate !== null) {
        this.search.serviceToDate = this.formatDate(this.serviceToDate)
      } else {
        this.search.serviceToDate = ''
      }

      await this.$getApi(`/api/appliance/dashboard/service-quality/between?endDate=${this.search.serviceToDate}&startDate=${this.search.serviceRequestFrom}&serviceType=${this.serviceType}`)
        .then(response => {
          this.serviceQuality = response.data
        }

        )
        .catch(error => { console.log(error) })
    },
    async changeASStatus(day) {
      await this.$getApi(`/api/appliance/dashboard/repair-request?periodStatus=${day}`)
        .then(response => {
          this.repairStatus = response.data
        }

        )
        .catch(error => { console.log(error) })
    },
    async changeServiceQualityStatus(day) {
      await this.$getApi(`/api/appliance/dashboard/service-quality?periodStatus=${day}&serviceType=${this.serviceType}`)
        .then(response => {
          this.serviceQuality = response.data
        })
        .catch(error => {
          console.log(error)
        })
    },
    formatDate(date) {
      const year = date.getFullYear()
      const month = ('0' + (date.getMonth() + 1)).slice(-2)
      const day = ('0' + date.getDate()).slice(-2)
      return `${year}-${month}-${day}`
    }
  }
}
</script>
<style scoped>
.custom-thead th {
  border-color: inherit;
  border-style: solid;
  background: #b9baba;
  color: #fff;
  border: 1px solid #b9baba;
  font-size: 14px;
}
.custom-th {
  padding: 10px 5px !important;
  border-style: solid !important;
  border-top: 1px solid #b9baba !important;
  border-left: 1px solid #b9baba !important;
  border-right: 1px solid #b9baba !important;
  background: #fff !important;
  color: #b9baba !important;
  font-size: 14px !important;
}
.custom-th1 {
  padding: 10px 5px !important;
  border-style: solid !important;
  border: 1px solid #fff !important;
  font-size: 14px !important;
}
</style>
