import axios from 'axios'
import store from '@/store'
// eslint-disable-next-line camelcase
import jwt_decode from 'jwt-decode'
import { mapGetters } from 'vuex'

axios.defaults.withCredentials = true

export default {
  data() {
    return {
      routeTo: [],
      routePath: ''
    }
  },
  computed: {
    ...mapGetters(['serviceType']),
    serviceTypeMapped() {
      const serviceType = this.serviceType
      // serviceType 값에 따라 대체할 값을 반환합니다.
      return serviceType === 'C' ? 'charger' : (serviceType === 'H' ? 'appliance' : serviceType)
    },
    authToken() {
      return store.state.user.id.authToken
    }
  },
  watch: {
    '$route' (to, from) {
      if (to.path) {
        this.routeTo = to
        this.routePath = to.path
      }
    }
  },
  methods: {
    async $refreshToken() {
      const vm = this
      const headers = {
        Authorization: store.state.user.id.authToken
      }
      await axios({
        url: `/api/${this.serviceTypeMapped}/auth/token/refresh`,
        method: 'post',
        headers: headers
      })
        .then((res) => {
          const authToken = res.data
          this.$store.commit('user', { loginId: store.state.user.id.loginId, authToken })
          sessionStorage.setItem('authToken', authToken)
        })
        .catch(error => {
          if (error.response.status === 403) {
            this.$store.commit('user', {})
            sessionStorage.removeItem('authToken')
            alert(this.$t('message.session'))
            vm.$router.push({ path: '/login' })
          }
        })
    },
    $tokenDecode() {
      const token = store.state.user.id.authToken
      const decodedToken = jwt_decode(token)
      return decodedToken
    },
    $isSystem() {
      const teamType = this.$store.state.adminTeamType
      console.log(`static called ${teamType === 'SYSTEM'}`)
      return teamType === 'SYSTEM'
    },
    async $api(url, method, data) {
      try {
        if (method === 'post' || method === 'patch' || method === 'delete' || method === 'put') {
          await this.$refreshToken()
        }
        const headers = {
          Authorization: store.state.user.id.authToken
        }
        const response = await axios({
          url: url,
          method: method,
          data: data,
          headers: headers
        })
        return {
          status: response.status,
          data: response.data
        }
      } catch (e) {
        return {
          status: e.response.status,
          data: e.response.data
        }
      }
    },
    async $noAuthApi(url, method, data) {
      try {
        const response = await axios({
          url: url,
          method: method,
          data: data
        })
        return {
          status: response.status,
          data: response.data
        }
      } catch (e) {
        if (e.response.status === 400) {
          alert(this.$t(`errorMessage.${e.response.data.code}`))
        } else {
          alert(this.$t('message.loginFail'))
        }
      }
    },
    async $getApi(url) {
      const vm = this
      return await axios.get(url, {
        headers: {
          Authorization: store.state.user.id.authToken,
          'Content-Type': 'application/json;charset=UTF-8'
        }
      })
        .catch(error => {
          if (error.response.status === 403) {
            this.$store.commit('user', {})
            sessionStorage.removeItem('authToken')
            alert(this.$t('message.session'))
            const popupPaths = [
              '/admin-register',
              '/admin-team-register',
              '/repair-staff-register',
              '/repair-staff-team-register',
              '/customer-history',
              '/station-register',
              '/charger-register',
              '/charger-model-register',
              '/charger-part-register',
              '/ha-product-register',
              '/ha-product-model-register',
              '/ha-product-part-register'
            ]
            console.log(this.routePath)
            if (popupPaths.includes(this.routePath)) {
              window.close()
            } else {
              vm.$router.push({ path: '/login' })
            }
          } else if (error.response.status === 400) {
            alert(this.$t(`errorMessage.${error.response.data.code}`))
          } else {
            alert(this.$t('message.error'))
          }
        })
    },
    async $excelApi(queryString) {
      return await axios.get(queryString, {
        responseType: 'blob',
        headers: {
          Authorization: store.state.user.id.authToken
        }
      })
    },
    async $fileApi(url, method, data) {
      const headers = {
        Authorization: store.state.user.id.authToken,
        'Content-Type': 'multipart/form-data'
      }
      return await axios({
        url: url,
        method: method,
        data: data,
        headers: headers
      })
        .catch(e => console.log(e))
    },
    $base64 (file) {
      return new Promise(resolve => {
        const a = new FileReader()
        a.onload = e => resolve(e.target.result)
        a.readAsDataURL(file)
      })
    },
    $currencyFormat (value, format = '#,###') {
      if (value === 0 || value == null) return 0

      let currency = format.substring(0, 1)
      if (currency === '$' || currency === '₩') {
        format = format.substring(1, format.length)
      } else {
        currency = ''
      }

      let groupingSeparator = ','
      let maxFractionDigits = 0
      let decimalSeparator = '.'
      if (format.indexOf('.') === -1) {
        groupingSeparator = ','
      } else {
        if (format.indexOf(',') < format.indexOf('.')) {
          groupingSeparator = ','
          decimalSeparator = '.'
          maxFractionDigits = format.length - format.indexOf('.') - 1
        } else {
          groupingSeparator = '.'
          decimalSeparator = ','
          maxFractionDigits = format.length - format.indexOf(',') - 1
        }
      }

      let prefix = ''
      let d = ''
      // v = String(parseFloat(value).toFixed(maxFractionDigits));

      let dec = 1
      for (let i = 0; i < maxFractionDigits; i++) {
        dec = dec * 10
      }

      let v = String(Math.round(parseFloat(value) * dec) / dec)

      if (v.indexOf('-') > -1) {
        prefix = '-'
        v = v.substring(1)
      }

      if (maxFractionDigits > 0 && format.substring(format.length - 1, format.length) === '0') {
        v = String(parseFloat(v).toFixed(maxFractionDigits))
      }

      if (maxFractionDigits > 0 && v.indexOf('.') > -1) {
        d = v.substring(v.indexOf('.'))
        d = d.replace('.', decimalSeparator)
        v = v.substring(0, v.indexOf('.'))
      }
      const regExp = /\D/g
      v = v.replace(regExp, '')
      const r = /(\d+)(\d{3})/
      while (r.test(v)) {
        v = v.replace(r, '$1' + groupingSeparator + '$2')
      }

      return prefix + currency + String(v) + String(d)
    },
    $phoneFormatter(num) {
      let formatNum = ''
      if (!(num === '' || num === 'undefined' || num === 'null' || num === null)) {
        num = num.replaceAll('-', '')
        formatNum = num
        if (num.length === 11) {
          formatNum = num.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3')
        } else if (num.length === 8) {
          formatNum = num.replace(/(\d{4})(\d{4})/, '$1-$2')
        } else {
          if (num.indexOf('02') === 0) {
            if (num.length === 9) {
              formatNum = num.replace(/(\d{2})(\d{3})(\d{4})/, '$1-$2-$3')
            } else {
              formatNum = num.replace(/(\d{2})(\d{4})(\d{4})/, '$1-$2-$3')
            }
          } else {
            if (num.length === 10) {
              formatNum = num.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')
            } else {
              formatNum = num.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3')
            }
          }
        }
      }
      return formatNum
    },
    $phoneFormatterHyphen(num) {
      let formatNum = ''
      if (!(num === '' || num === 'undefined' || num === 'null' || num === null)) {
        num = num.replaceAll('-', '')
        formatNum = num
      }
      return formatNum
    },
    $fnSearchReset() {
      for (const key in this.search) {
        if (key !== 'partnerId') {
          this.search[key] = ''
        }
      }
    },
    $formatNumber(number, event) {
      if (number === 0 || number === '0') {
        return number
      } else {
        const beforeNumber = number.toString().replace(/,/g, '')
        const result = beforeNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        return result
      }
    },
    $disFormatNumber(number) {
      if (number === 0 || number === '0') {
        return number
      } else if (number === '') {
        return 0
      } else if (typeof number === 'number') {
        return number
      } else {
        return number.replace(/,/g, '')
      }
    },
    $requestTypeFomatter(value) {
      let formatValue = ''
      if (value === 'CALL_COMPLETE') {
        formatValue = '전화 문의 완료'
      } else if (value === 'VISIT_REQUIRED') {
        formatValue = '현장 방문 필요'
      } else if (value === '') {
        formatValue = '-'
      }

      return formatValue
    },
    $requestStatusFomatter(value) {
      let formatValue = ''
      if (value === 'R_REQUEST') {
        formatValue = '접수'
      } else if (value === 'R_ASSIGNMENT') {
        formatValue = '배정'
      } else if (value === 'R_VISIT') {
        formatValue = '방문예정'
      } else if (value === 'R_REPAIR') {
        formatValue = '수리중'
      } else if (value === 'R_DELAY') {
        formatValue = '지연'
      } else if (value === 'R_REVISIT') {
        formatValue = '재방문예정'
      } else if (value === 'R_COMPLETE') {
        formatValue = '완료'
      } else if (value === 'R_CONFIRM') {
        formatValue = '확정'
      } else if (value === '') {
        formatValue = '-'
      }

      return formatValue
    },
    $classInsert(value) {
      let className = ''
      if (value === '접수') {
        className = 'status-receipt'
      } else if (value === '배정') {
        className = 'status-assignment'
      } else if (value === '방문예정') {
        className = 'status-expected'
      } else if (value === '수리중') {
        className = 'status-repair'
      } else if (value === '재방문예정') {
        className = 'status-revisit'
      } else if (value === '완료') {
        className = 'status-complete'
      } else if (value === '확정') {
        className = 'status-confirm'
      } else if (value === '') {
        className = '-'
      }
      return className
    },
    $stringCut(value) {
      const match = value.match(/\d+/)

      return match ? match[0] : ''
    },
    $formatDate(date) {
      const regex = /^\d{4}-\d{2}-\d{2}$/

      if (regex.test(date)) {
        return date
      } else {
        const year = date.getFullYear()
        const month = ('0' + (date.getMonth() + 1)).slice(-2)
        const day = ('0' + date.getDate()).slice(-2)
        return `${year}-${month}-${day}`
      }
    },
    $logFormData(formData) {
      for (const [key, value] of formData.entries()) {
        if (value instanceof Blob) {
          value.text().then(text => console.log(key, text))
        } else {
          console.log(key, value)
        }
      }
    },
    // 핸드폰 번호, 지역 번호, 상용 번호(ex:1577-1577) 체크
    $checkPhoneNumber(phoneNumber) {
      const landlinePattern = /^(02|031|032|033|041|042|043|044|051|052|053|054|055|061|062|063|064|070)-\d{3,4}-\d{4}$/
      const mobilePattern = /^(010|011|016|017|018|019)-\d{3,4}-\d{4}$/
      const commercialPattern = /^\d{4}-\d{4}$/
      if (!phoneNumber ||
          (!landlinePattern.test(phoneNumber) && !mobilePattern.test(phoneNumber) && !commercialPattern.test(phoneNumber))) {
        alert(`${this.$t('register.customer')} ${this.$t('register.tel')}${this.$t('message.phoneNumberNotCorrect')}`)
        this.$refs.phoneNumberInput.focus()
        return false
      }
      return true
    }
  }
}
